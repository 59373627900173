// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ie-tsx": () => import("./../../../src/pages/ie.tsx" /* webpackChunkName: "component---src-pages-ie-tsx" */),
  "component---src-templates-blog-post-page-tsx": () => import("./../../../src/templates/blog-post-page.tsx" /* webpackChunkName: "component---src-templates-blog-post-page-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */)
}

